function getCookie(name) {
  const parseCookiesToObjectEntries = (cookies) =>
    cookies.split(";").map((el) => {
      const [k, v] = el.split("=");
      return [k.trim(), v];
    });

  const composeConvertCookiesToObject = (fromEntries, parseCookies) => (
    cookies
  ) => fromEntries(parseCookies(cookies));

  return composeConvertCookiesToObject(
    Object.fromEntries,
    parseCookiesToObjectEntries
  )(document.cookie)[name];
}

function setCookie(name, days, value = "") {
  const expiryDate = (days) => {
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      return "; expires=" + date.toUTCString();
    }

    return "";
  };

  document.cookie = name + "=" + value + expiryDate(days) + "; path=/";
}

function showAcceptCookieWarning() {
  const isCookieWarningNotAccepted =
    getCookie("accept-cookie-warning") !== "true";

  if (!!isCookieWarningNotAccepted) {
    const cookieWarningBar = document.getElementsByClassName("cookie-bar")[0];
    if (cookieWarningBar) {
      const acceptButton = document.getElementsByClassName(
        "cookie-bar--bt-accept"
      )[0];

      if (acceptButton) {
        cookieWarningBar.classList.add("visible");

        acceptButton.addEventListener("click", () => {
          setCookie("accept-cookie-warning", 30, "true");

          cookieWarningBar.classList.add("closed");
        });
      }
    }
  }
}

setTimeout(showAcceptCookieWarning, 3000);
